.Legal {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  margin-bottom: 50px;
}
.Legal ul {
  margin-left: 40px;
}
.Legal ul li {
  list-style: disc;
  margin-bottom: 10px;
  font-size: clamp(1rem, 0.9063rem + 0.75vw, 1.375rem);
  line-height: 150%;
}
