.Estimate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 50px;
}
.Estimate .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
}
.Estimate header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 30px;
}
.Estimate header img {
  height: 30px;
  margin-bottom: 15px;
  object-fit: contain;
}
.Estimate header .AlertSmall {
  margin-top: 30px;
}

.Estimate .form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.Estimate .form .price {
  text-align: center;
  margin-bottom: 30px;
  color: var(--c-gray-text);
}
.Estimate .form .price span {
  font-size: 40px;
  color: var(--c-green);
}
.Estimate .form .AlertSmall {
  margin-top: -10px;
  margin-bottom: 20px;
}
.Estimate .form .container {
  cursor: pointer;
  border: var(--b-default);
  border-radius: var(--br-default);
  padding: 10px;
  transition: var(--tr-default) background-color;
  margin-bottom: 20px;
}
.Estimate .form .container:hover {
  background-color: #f7f7f7;
}
.Estimate .form input:checked + .container {
  outline: 2px solid var(--c-default);
  outline-offset: 2px;
}
.Estimate .form .ctn-input {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.Estimate .ctn-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Estimate .success {
  margin-bottom: 20px;
}
.Estimate .success p .icon {
  color: var(--c-green);
  margin-right: 10px;
  margin-bottom: -3px;
}
