/* ================================================= Header ================================================= */

.Landing header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh + 14.7vw);
  background-color: var(--c-default);
  color: white;
}
.Landing header .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.Landing header .inner h1 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  max-width: 700px;
  line-height: 95%;
  gap: 10px 20px;
}
.Landing header .inner h1 span {
  overflow: hidden;
}
.Landing header .inner h1 div {
  animation: showText 1s forwards;
  animation-delay: 0s;
  transform: translateY(100%);
  opacity: 0;
}
.Landing header .inner h1 div.second {
  animation-delay: 0.3s;
}
.Landing header .inner h1 div.last {
  animation-delay: 0.5s;
}
@keyframes showText {
  0% {
    transform: translateY(100%) rotate(10deg);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}
.Landing header .inner p {
  max-width: 500px;
  margin-bottom: 50px;
}
.Landing header .inner .ButtonComponent {
  font-size: 17px;
  box-shadow: var(--bs-default);
}
.Landing header .ctn-text {
  flex: 1;
}
.Landing header .ctn-img {
  flex: 0.5;
}
.Landing header .ctn-img img {
  height: 60vh;
  width: 100%;
  object-fit: cover;
  border-radius: var(--br-default);
  box-shadow: var(--bs-default);
}
.Landing header .ctn-img .container {
  position: absolute;
  transform: translate(25px, -300%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  font-weight: 600;
  padding: 10px;
  opacity: 0;
  background-color: white;
  color: var(--c-green);
  border-radius: var(--br-default);
  box-shadow: var(--bs-ctn);
  animation: show 1s forwards;
  animation-delay: 0.7s;
}
.Landing header .ctn-img .container:nth-child(2) {
  transform: translate(17vw, -300px);
  animation: show2 1s forwards;
  animation-delay: 0.9s;
}
.Landing header .ctn-img .container:last-child {
  transform: translate(-45px, -450px);
  animation: show3 1s forwards;
  animation-delay: 1.1s;
}

@keyframes show {
  0% {
    opacity: 0;
    transform: translate(-30px, -250%);
  }
  100% {
    opacity: 1;
    transform: translate(-30px, -300%);
  }
}
@keyframes show2 {
  0% {
    opacity: 0;
    transform: translate(17vw, -250px);
  }
  100% {
    opacity: 1;
    transform: translate(17vw, -300px);
  }
}
@keyframes show3 {
  0% {
    opacity: 0;
    transform: translate(-45px, -400px);
  }
  100% {
    opacity: 1;
    transform: translate(-45px, -450px);
  }
}

.Landing header .ctn-img .container div {
  display: flex;
  align-items: center;
}
.Landing header .ctn-img .container span {
  margin-left: 10px;
}
.Landing header .ctn-img .container .icon.arrow {
  font-size: 25px;
}

.Landing header > img {
  width: 100%;
}

/* --------- Responsive --------- */
@media screen and (max-width: 780px) {
  @keyframes show {
    0% {
      opacity: 0;
      transform: translate(calc(20px - 10vw), -250%);
    }
    100% {
      opacity: 1;
      transform: translate(calc(20px - 10vw), -300%);
    }
  }
  @keyframes show2 {
    0% {
      opacity: 0;
      transform: translate(14vw, -250px);
    }
    100% {
      opacity: 1;
      transform: translate(14vw, -300px);
    }
  }
  @keyframes show3 {
    0% {
      opacity: 0;
      transform: translate(-10px, -400px);
    }
    100% {
      opacity: 1;
      transform: translate(-10px, -450px);
    }
  }
}

@media screen and (max-width: 750px) {
  .Landing header .inner {
    flex-direction: column;
    gap: 50px;
  }
  .Landing header .inner .ctn-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 150px;
    max-width: 80vw;
  }
  .Landing header .inner .ctn-text h1 {
    display: flex;
    justify-content: center;
  }
  .Landing header .inner .ctn-img img {
    width: 100%;
    max-width: 350px;
    height: 60vh;
  }
  @keyframes show2 {
    0% {
      opacity: 0;
      transform: translate(calc((300px - 100%) + 10vw), -250px);
    }
    100% {
      opacity: 1;
      transform: translate(calc((300px - 100%) + 10vw), -300px);
    }
  }
}

@media screen and (max-width: 480px) {
  .Landing header .inner .ctn-img img {
    width: 100%;
    max-width: 300px;
    height: 400px;
    margin-bottom: 50px;
  }
}

/* ========================================================================================================== */

/* ================================================== Main ================================================== */

.Landing main {
  margin-bottom: 10vh;
}

/* ========================================================================================================== */

/* ================================================== About ================================================= */

.Landing main .about {
  margin-top: 10vh;
  min-height: 50vh;
}
.Landing main .about .ctn-text {
  overflow: hidden;
  background-image: url("./image.jpg");
  background-size: contain;
  border-radius: var(--br-default);
}
.Landing main .about .ctn-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  object-fit: cover;
}
.Landing main .about p {
  max-width: 500px;
}
.Landing main .about p,
.Landing main .about h2,
.Landing main .about .ctn-button {
  background-color: white;
  margin: 0;
  border-bottom-right-radius: var(--br-default);
  box-shadow: 20px 19px 20px 0px rgb(160 160 160 / 10%);
  padding: 15px 20px;
}
.Landing main .about .ctn-button {
  min-width: 20%;
  border-radius: 0px;
}

/* .Landing main .about p {
  max-width: 500px;
}
.Landing main .about .ctn-image {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 50px;
}
.Landing main .about .ctn-image img {
  width: 100%;
  height: 80vw;
  max-height: calc(100vh - 80px);
  border-radius: var(--br-default);
  object-fit: cover;
}
.Landing main .about .ctn-image div:first-child {
  flex: 1;
  margin-top: 100px;
}
.Landing main .about .ctn-image div:last-child {
  flex: 1.3;
} */

/* ========================================================================================================== */

/* ================================================= Quote ================================================== */

.Landing main .quote {
  text-align: center;
  max-width: 800px;
  padding: 150px 0;
}
.Landing main .quote span {
  color: var(--c-default);
}

/* ========================================================================================================== */

/* ================================================ Features ================================================ */

.Landing main .features .wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.Landing main .features .wrapper .container {
  overflow: hidden;
  border-radius: var(--br-default);
  padding: 40px;
  margin-bottom: 0;
}
.Landing main .features .wrapper .container .p-40 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Landing main .features .wrapper .container img {
  width: 100%;
}
.Landing main .features .wrapper .container h3 {
  font-weight: 600;
  margin: 0;
  font-size: clamp(22px, 7vw, 35px);
  line-height: clamp(17px, 7vw, 30px);
  color: white;
}
.Landing main .features .wrapper > .container p {
  margin-top: 20px;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.847);
}
.Landing main .features .wrapper > .container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--c-secondy-bg);
  color: white;
  text-align: center;
}
.Landing main .features .wrapper > .container img {
  width: 100%;
  max-width: 500px;
  border-top-left-radius: var(--br-default);
  border-top-right-radius: var(--br-default);
}
.Landing main .features .wrapper .flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Landing main .features .wrapper .flex .container {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: var(--c-gray-bg);
  margin-bottom: 0;
}
.Landing main .features .wrapper .flex .container:last-child {
  flex: 1;
  background-color: var(--c-default);
  gap: 20px;
  color: white;
}
.Landing main .features .wrapper .flex .container .ctn-image {
  width: 100%;
  height: 100%;
}

.Landing main .features .wrapper .flex .container .ctn-image img {
  object-fit: contain;
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .Landing main .features .wrapper {
    display: flex;
    flex-direction: column;
  }
  .Landing main .features .wrapper .flex {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 900px) {
  .Landing main .features .wrapper .flex {
    display: flex;
    flex-direction: column;
  }
  .Landing main .features .wrapper .flex .ctn-image {
    max-height: 250px;
  }
}
@media screen and (max-width: 480px) {
  .Landing main .features .wrapper .flex .ctn-image {
    display: none;
  }
  .Landing main .features .wrapper img {
    margin-top: 20px;
  }
  .Landing main .features .wrapper .container,
  .Landing main .features .wrapper .p-40 {
    padding: 20px;
  }
}

/* ========================================================================================================== */

/* ================================================= Project ================================================ */

.project {
  margin-top: 100px;
}

/* ========================================================================================================== */

/* ================================================= Contact ================================================ */

.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-top: 100px;
}
.contact > div {
  flex: 1;
}
.contact .ctn-img {
  height: 600px;
  width: 100%;
  border-radius: var(--br-default);
}
.contact img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: var(--br-default);
}

/* --------- Responsive --------- */

@media screen and (max-width: 850px) {
  .contact {
    display: flex;
  }
  .contact .ctn-img {
    display: none;
  }
  .contact h3 {
    font-size: clamp(1.625rem, 0.9375rem + 5.5vw, 4.375rem);
  }
  .contact .ContactForm {
    max-width: 100%;
  }
}
